export default [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "库位编号",
    dataIndex: "location_number",
    width: 120,
    ellipsis: true,
  },
  {
    title: "仓库",
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "产品编号",
    dataIndex: "material_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: "产品名称",
    dataIndex: "material_name",
    width: 240,
  },
  {
    title: "批次编号",
    dataIndex: "batch_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: "流水类型",
    dataIndex: "type_display",
    width: 120,
    ellipsis: true,
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    width: 180,
    ellipsis: true,
  },
  {
    title: "变化之前",
    dataIndex: "quantity_before",
    width: 100,
    ellipsis: true,
  },
  {
    title: "变化数量",
    dataIndex: "quantity_change",
    width: 100,
    ellipsis: true,
  },
  {
    title: "变化之后",
    dataIndex: "quantity_after",
    width: 100,
    ellipsis: true,
  },
  {
    title: "单据编号",
    dataIndex: "order_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: "客户单号",
    dataIndex: "external_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: "创建人",
    dataIndex: "creator_name",
    width: 120,
    ellipsis: true,
  },
];
